// @format
import React, { Component } from 'react';
import styled from 'styled-components';
import { HUNTER_GREEN, RHINO } from './constants/colors';
import imgLandingBg from './assets/img/landing-bg.png';

const Canvas = styled.div`
  background-color: ${HUNTER_GREEN};
`;

const Section = styled.div`
  width: 100vw;
  height: 100vh;
`;

const LandingSection = styled(Section)`
  background-image: url(${imgLandingBg});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1em;
  box-sizing: border-box;
`;

const SiteTitle = styled.div`
  color: ${RHINO};
  font-family: 'Special Elite', cursive;
  font-size: 2.25em;
  line-height: 3.375em;
`;

class App extends Component {
  render() {
    return (
      <Canvas>
        <LandingSection>
          <SiteTitle>Taco Rat Club.</SiteTitle>
        </LandingSection>
      </Canvas>
    );
  }
}

export default App;
